import styled from 'styled-components';
import { respondTo } from '../../../styles/shared/breakpoints';
import { lightGray, black } from '../../../styles/shared/colors';

export const FooterContainer = styled.div`
  background-color: ${lightGray};
  max-width: 1032px;
  margin: 20px auto 0 auto;
`;

export const ContentWrapper = styled.div`
  padding: 15px 10px 20px 10px;
  max-width: 1032px;
  margin: auto;
  position: relative;
`;

export const TextWrapper = styled.div`
    ${respondTo.sm`
      margin-left: 42px;
    `};
`

export const LogoRow = styled.div`
  margin: 10px 0 10px 0;
  ${respondTo.sm`
    display: flex;
    align-items: center;
  `};
`;

export const FooterExternalLinks = styled.ul`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  
  ${respondTo.sm`
    gap: 16px;
    margin-left: 15px;
    padding-bottom: 3px;
  `};

  li{
    position: relative;

    a{
      &:hover{
        text-decoration: underline;
      }
    }

    &:after {
      content: '•';
      position: absolute;
      font-size: 14px;
      color: ${black};
      right: -7px;
      top: 2px;

      ${respondTo.sm`
        right: -10px;
      `}
    }

    &:last-child{
      &:after {
        content: '';
      }
    }
  }
`;

export const AccessibilityLogoWrapper = styled.div`
  margin-top: 10px;
  width: 75px;

  ${respondTo.sm`
    position: absolute;
    top: 10px;
    right: 10px;
  `}
`

export const MerckLogoWrapper = styled.div`
  display: flex;
  width: 115px;
  height: 33.781px;
`;

