import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import fonts from './_fonts';
import general from './_general';


const GlobalStyles = createGlobalStyle`
  ${reset}
  ${fonts}
  ${general}
`;


export default GlobalStyles;