import accessibilityLogo from '../assets/images/Accessibility.svg';
import MerckLogo from '../assets/images/Merck_Logo_RGB.svg'
import yourPrivacyChoicesImg from '../assets/images/yourprivacychoices.svg';
import { jobCodeGlobal} from "../util/jobCode.js";

export const headerCopy = {

  mapLogoText: 'Home',
};

export const footerCopy = {
  footerTop: "This site is intended only for residents of the  United States.",

  legalLinks: [
    {
      label: 'Terms of Use',
      newTab: true,
      href: 'https://www.merck.com/terms-of-use/'
    },
    {
      label: 'Privacy Policy',
      newTab: true,
      href: 'https://www.msdprivacy.com/us/en/'
    },
    {
      label: 'Cookie Preferences',
      class: "ot-sdk-show-settings",
    },
    {
      label: 'Accessibility',
      newTab: true,
      href: 'https://www.levelaccess.com/a/merck/'
    },
    {
      label: 'Your Privacy Choices',
      newTab: true,
      href: 'https://dsr.msd.com/DSR/United_States/English/',
      image: yourPrivacyChoicesImg
    },
    {
      label: 'Consumer Health Data Privacy Policy',
      newTab: true,
      href: 'https://www.msdprivacy.com/us/en/chd-policy/'
    }

  ],

  copyrightGlobal: [
    `Copyright © 2024 Merck & Co., Inc., Rahway, NJ, USA and its affiliates. All rights reserved.&nbsp;${jobCodeGlobal}`,
  ],

  legalLinksAfterCookie: [
    {
      label: 'Accessibility',
      url: 'https://www.msdaccessibility.com'
    },
  ],

  logos: {
    accessibility: {
      src: accessibilityLogo,
      alt: 'accessibily link',
      href: "https://www.levelaccess.com/a/merck/"
    },

    merck: {
      src: MerckLogo,
      alt: 'Merck Logo'
    }
  },
};
