import styled from 'styled-components';
import { Link } from 'gatsby';
import { tealTransparent, black } from '../../../styles/shared/colors';
import { respondTo } from '../../../styles/shared/breakpoints';
import { LinkText } from '../../../styles/shared/typography';

export const HeaderWrapper = styled.div`
  margin: 20px 10px;
  display: flex;
  justify-content: center;
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  width: 1032px;
`

export const LogoLink = styled(Link)`
  width: 100%;
`;

export const Logo = styled.img`
  width: 110px;
  
  ${respondTo.sm`
    width: 165px;
  `}
`;

export const UtilNav = styled.nav`
  padding: 0;

  ${respondTo.sm`
    display: flex;
    align-items: center;
  `}
`;

export const NavLink = styled(LinkText)`
  font-weight: normal;
  white-space: nowrap;
  text-align: end;
  padding: 7px;

  &.current {
    background: ${tealTransparent};
  }

  &:first-child {
       position: relative; 

       &:after {
         ${respondTo.sm`
         content: '';
         height: 13px;
         width: 1px;
         background: ${black};
         display: block;
         position: absolute;
         right: 0;
         top: 8px;
       `}
       }
      }
`;

export const MerckLogoWrapper = styled.div`
  margin: 0 10px 20px;
  display: flex;
  justify-content: center;

  img {
    width: 100%;
    height: auto;
    max-width: 500px;
  }
`;
