import React from 'react';
import { ContentWrapper, TextBoxContent, ButtonContainer } from './styles';
import Button from '../Button';
import IconHome from '../../../assets/images/icons/icon-home.png';

const Textbox = ({ textboxData }) => {
  return (
   
      <ContentWrapper>
        <TextBoxContent>{textboxData.sectionTitle}</TextBoxContent>
        <ButtonContainer>
                <Button to="/" buttonLabel="Enroll Now"><img src={IconHome}/> HOME</Button> 
        </ButtonContainer>
      </ContentWrapper>
   
  );
};

export default Textbox;
