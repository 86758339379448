import { between } from "polished";

export const createResponsiveFontRules = (
    minFontSize,
    minLineHeight,
    maxFontSize = null,
    maxLineHeight = null
) => {
    const minScreen = 320,
        maxScreen = 1032;

    let rules = `
        font-size: ${minFontSize}px;
        line-height: ${minLineHeight}px;`;

    if (maxFontSize && maxLineHeight) {
        rules += `
        @media (min-width: ${minScreen + 1}px) and (max-width: ${maxScreen}px) {
            font-size: ${between(
                minFontSize + 'px',
                maxFontSize + 'px',
                minScreen + 'px',
                maxScreen + 'px'
            )};
            line-height: ${between(
                minLineHeight + 'px',
                maxLineHeight + 'px',
                minScreen + 'px',
                maxScreen + 'px'
            )};
        }

        @media (min-width: ${maxScreen + 1}px) {
            font-size: ${maxFontSize}px;
            line-height: ${maxLineHeight}px;
        }
        `;
    }
    return rules;
};