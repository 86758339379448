import React, { useState } from 'react';
import { ButtonWrapper } from './styles';
import { setPropsFromURL } from '../../../util/cta';

const Button = ({ to, children }) => {
  const linkProps = (to) ? setPropsFromURL(to) : {as: 'button'};
  const [buttonLabel, setButtonLabel] = useState(children);

  return (
    <ButtonWrapper {...linkProps}>{buttonLabel}</ButtonWrapper>
  );
};

export default Button;
