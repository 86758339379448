import React from 'react';
import { ListContainer, ListItem, IconWrapper, Icon, CopyWrapper, BodyText, SubCopy } from './styles';


const IconList = ({ icon, copy, subcopy, alt, audience}) => {
  return (
    <ListContainer>
      <ListItem>
        <Icon src={icon} alt={alt}></Icon>
        <CopyWrapper>
          <BodyText audience={audience} >{copy}
          <SubCopy>{ subcopy }</SubCopy></BodyText>
        </CopyWrapper>
      </ListItem>      
    </ListContainer>
  );
};


export default IconList;
