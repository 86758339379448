import styled from 'styled-components';
import { lightTeal, white, grey, green, offWhite, black } from '../../../styles/shared/colors';
import { WrapperEl } from '../../../styles/shared/layout';
import { respondTo } from '../../../styles/shared/breakpoints';

export const SectionWrapper = styled(WrapperEl)`
  padding: 25px 10px;
  background-color: ${lightTeal};
  box-shadow: inset 0px 8px 4px -4px ${black}28;
`;

export const HeaderContainer = styled.div`
  text-align: center;
  max-width: 212px;
  margin: 0 auto 10px auto;
  ${respondTo.sm`
    max-width: 940px;
    margin: 0 auto 20px auto;
  `}
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
`;

export const CardContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(262px, 1fr));
  height: 100%;
  gap: 15px;
  margin: auto;

  ${respondTo.sm`
    padding: 0;
    gap: 16px;
    
    min-height: 615px; //do i need
    max-width: 940px; //do i need0...
    
  `}
`;

export const Card = styled.div`
  width: 100%;
  height: inherit;
  background-color: ${white};
  box-shadow: 0 3px 6px 0 ${offWhite};
  padding: 15px 15px 10px 15px;
  display: flex;
  flex-direction: column;
`

export const CardTopContainer = styled.div``

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  height: 110px;

  img{
    display: block;
    width: 220px; //may want to grow
    height: auto;
  }
`;

export const CardLinkContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 10px;
`;
