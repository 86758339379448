// import PrimaryLink from '../PrimaryLink/'
import React from "react";
import { CardContainer, Card, HeaderContainer, SectionWrapper, LogoContainer, CardLinkContainer, ButtonContainer, CardTopContainer } from './styles';
import { HeadingB, BodyB } from '../../../styles/shared/typography';
// import Button from '../Button';

import { Button, PrimaryLink } from '../index'

const Cards = ({ cardAudience }) => {
  return (
    <SectionWrapper>

      <HeaderContainer>
            <HeadingB>{cardAudience.title}</HeadingB>
      </HeaderContainer>

      <CardContainer tealTransparent>
        {cardAudience.cards.map((card, i) => (
          <Card key={i}>
            
            <CardTopContainer>
              <LogoContainer><img src={card.logo} alt={card.alt} /></LogoContainer>
              
              <ButtonContainer>
                <Button to={card.hrefButton} buttonLabel="Enroll Now">Enroll Now</Button> 
              </ButtonContainer>

              <BodyB dangerouslySetInnerHTML={{ __html: card.description }}/>
            </CardTopContainer>

            <CardLinkContainer>
                {
                  card.links.map((link, i) => (
                    <PrimaryLink key={i} text={link.title} url={link.url} alt={link.alt} />
                  ))
                }
            </CardLinkContainer>
          </Card>
        ))
        }
      </CardContainer>
    </SectionWrapper>
  );
};

export default Cards;
