import invention_Lt from '../../assets/fonts/Invention_Lt.ttf';
import invention_LtIt from '../../assets/fonts/Invention_LtIt.ttf';
import invention_Rg from '../../assets/fonts/Invention_Rg.ttf';
import invention_It from '../../assets/fonts/Invention_It.ttf';
import invention_Bd from '../../assets/fonts/Invention_Bd.ttf';
import invention_BdIt from '../../assets/fonts/Invention_BdIt.ttf';

const fontData = [
  { src: invention_Lt,     style: 'normal', weight: '300', family: 'invention' },
  { src: invention_LtIt,   style: 'italic', weight: '300', family: 'invention' },
  { src: invention_Rg,     style: 'normal', weight: '400', family: 'invention' },
  { src: invention_It,     style: 'italic', weight: '400', family: 'invention' },
  { src: invention_Bd,     style: 'normal', weight: '700', family: 'invention' },
  { src: invention_BdIt,   style: 'italic', weight: '700', family: 'invention' },
];

const fonts = fontData.map(font => `
  @font-face {
    font-family: ${font.family};
    src: url('${font.src}') format('truetype');
    font-style: ${font.style};
    font-weight: ${font.weight};
  }
`).join('')

export default fonts;
