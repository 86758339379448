const GeneralStyles = `
* {
  box-sizing: border-box;
}
html,
body {
  max-width: 100%;
  -webkit-font-smoothing: antialiased;

  &.no-scroll {
    // Some extra properties here to prevent scrolling on iOS
    -webkit-overflow-scrolling: none;
    overflow: hidden;
    touch-action: none;
    overscroll-behavior: none;
  }
}
body {
  font-family: 'invention', sans-serif;
  font-size: 16px;
  overflow-x: hidden;
  p {
    -webkit-font-smoothing: antialiased;
  }

  &.prevent-scroll {
    overflow: hidden;
  }
}
a,
button {
  font-family: inherit;
}
sup {
  vertical-align: top;
  position: relative;
  top: -0.3em;
  font-size: max(50%, 10px);
}
sub {
  vertical-align: bottom;
  position: relative;
  bottom: -0.3em;
  font-size: max(42%, 10px);
}
.no-wrap {
  white-space: nowrap;
}
em {
  font-style: italic;
}
strong {
  font-weight: bold;
}
`;


export default GeneralStyles;