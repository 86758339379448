import React from 'react';
import PropTypes from 'prop-types';
import { LinkWrapper, LinkLabel, LinkIcon, CircleWrapper } from './styles';
import ArrowIcon from '../../../assets/images/icons/Arrow.svg';

const PrimaryLink = ({ text, url, alt }) => {
  return (
    <LinkWrapper to={url} target="_blank">
      <LinkLabel>{text}</LinkLabel>
      <CircleWrapper>
        <LinkIcon src={ArrowIcon} alt={alt} />
      </CircleWrapper>
    </LinkWrapper>
  );
};

PrimaryLink.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default PrimaryLink;
