import styled from 'styled-components';
import { darkLinkBlue, offWhite } from '../../../styles/shared/colors';
import { LinkText } from '../../../styles/shared/typography';

export const LinkWrapper = styled(LinkText)`
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: 600;
  color: ${darkLinkBlue};
  width: max-content;

  &:hover {
    cursor: pointer;
  }

  &:visited {
    color: ${darkLinkBlue};
  }
`;

export const CircleWrapper = styled.div`
  position: relative;
  display: inline-block;
  padding: 10px;
  border: 1px solid ${offWhite};
  border-radius: 50%;
  width: 30px;
  height: 30px;

  img {
    position: absolute;
    top: 8px;
    left: 8px;
  }
`;

export const LinkLabel = styled.span`
  margin-right: 8px;
`;

export const LinkIcon = styled.img`
  width: 13px;
  height: 13px;
`;
