import styled, { css } from 'styled-components';
import { respondTo } from './breakpoints';

export const WRAPPER_WIDE_MAX_WIDTH = '1032px';
export const WRAPPER_NARROW_MAX_WIDTH = '872px';


export const WrapperCss = css`
  width: 100%;
  max-width: ${({useNarrow}) => useNarrow ? WRAPPER_NARROW_MAX_WIDTH : WRAPPER_WIDE_MAX_WIDTH};
  margin: 0 auto;
  padding: 0 30px;
  ${respondTo.sm`
    padding: 0 16px;
  `}
`;

export const WrapperEl = styled.div`
  ${WrapperCss};
`;