import Link from 'gatsby-link';


export const setPropsFromURL = (url, newWindow = true) => {
  if (!url) {
    return;
  }

  let props = {};

  //strip the domain if it's local
  let regex = /^(http|https):\/\/welireghcp.com/;
  url.replace(regex, '');

  if (url.endsWith('.pdf')) { // Download
    props.href = url;
    props.download = true;
    props.target = (newWindow) ? '_blank' : '_self';
    props.as = 'a';

  } else if (url.startsWith('http')) { // External
    props.href = url;
    props.external = true;
    props.target = (newWindow) ? '_blank' : '_self';
    props.as = 'a';

  } else { // Local
    props.to = url;
    props.as = Link;
  }

  return props;
};
