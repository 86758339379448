import React from 'react';
import {useLocation} from '@reach/router';
import { Header, Footer } from '../../elements';
import GlobalStyles from '../../../styles/global';
import * as s from './styles';


const PageLayout = ({children}) => {

  const location = useLocation();
  const is404Page = location.pathname === "/404/";

  return (
    <s.StyledLayout>
      <GlobalStyles />
      {is404Page ? null : <Header />}
      
      <s.PageMain>
        {children}
      </s.PageMain>

     
 
    </s.StyledLayout>
  );
}

export default PageLayout