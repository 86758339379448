import styled from 'styled-components';
import { Link } from 'gatsby';
import * as colors from '../../../styles/shared/colors';
import { ButtonText } from '../../../styles/shared/typography';

export const ButtonWrapper = styled(ButtonText)`
  padding: 14px 29px;
  margin: 10px 0;
  background-color: ${colors.green};
  width: fit-content;
`;
