import styled from 'styled-components';
import { respondTo } from '../../../styles/shared/breakpoints';
import { black, green, lightGray } from '../../../styles/shared/colors';
import { HeadingA } from '../../../styles/shared/typography';

export const HeroContainer = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 1032px;
  margin: auto;

  ${respondTo.sm`
    flex-direction: row;
    box-shadow: 0px -6px 6px -8px ${black}20;
  `}
`;
export const HeroImageWrapper = styled.div`
  ${respondTo.sm`
    flex: 1;
    margin: unset;
    object-fit: cover;
  `}
`

export const ImageWrapper = styled.div`
  max-width: 550px;
  margin: 0 auto;
  width: 100%;
  position: relative;

  ${respondTo.sm`
    flex: 1;
    margin: unset;
    max-width: 400px;
  `}
`;

export const HeroShadow = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 2px;
  left: 0;
  box-shadow: 0px 3px 6px 0px ${black}30;
  ${respondTo.sm`
    bottom: 0;
    box-shadow: inset -6px 0px 3px -3px ${black}18;
  `} 
`;

export const HeroImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ContentWrapper = styled.div`
    max-width: 610px;
    margin: 20px auto 0;
    align-self: center;

    ${respondTo.sm`
      width: 100%;
      margin: ${props => props.audience === 'hcp' ? '20px 10px 0' : '20px auto 0'};
    `} 
`;

export const HeroTitle = styled(HeadingA)`
    color: ${green};
    margin: auto;
    text-align: center;
    width: 90%;
    display: flex;
`;

export const HeroIconWrapper = styled.div`
  display: block;
  padding: 10px 10px 20px;
  vertical-align: top;
  
  ${respondTo.sm`
      width: 100%;
      display: flex;
      padding: 20px 0;
  `}  
`;

export const Hcc = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  
  ${respondTo.sm`
    flex-direction: row;
  `}
`;

export const Hcp = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${respondTo.md`
      flex-direction: row;
      flex-wrap: wrap;
      gap: 0;
  `}
  
  div {
    li {
      display: flex;

      ${respondTo.sm`
        margin: 5px 0px;
        flex-direction: row;
      `}
    }
  }
`;