import React from 'react';
import { HeroContainer, ImageWrapper, HeroShadow, HeroImage, ContentWrapper, Hcp, HeroTitle, HeroIconWrapper, } from './styles';
import IconItem from '../IconList';
import hcpHero from '../../../assets/images/hcp-hero.png';

const Hero = ({ heroData }) => {
  return (
    <HeroContainer>
      <ImageWrapper>
        <HeroShadow />
        <HeroImage src={hcpHero} alt="" />
      </ImageWrapper>
      <ContentWrapper audience={heroData.audience}>
        <HeroTitle>{heroData.sectionTitle}</HeroTitle>
        <HeroIconWrapper>

          {/*  */}

            <Hcp>
              {heroData.icons.map((item, i) =>
                <IconItem
                  key={i}
                  icon={item.icon}
                  copy={item.copy}
                  subcopy={item.subcopy}
                  audience={heroData.audience}
                />,
              )}
            </Hcp>

        </HeroIconWrapper>
      </ContentWrapper>
    </HeroContainer>
  );
};

export default Hero;
