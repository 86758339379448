import styled from 'styled-components';
import { respondTo } from '../../../styles/shared/breakpoints';
import { black, green, lightGray } from '../../../styles/shared/colors';
import { HeadingA } from '../../../styles/shared/typography';


export const ContentWrapper = styled.div`
    max-width: 610px;
    margin: 20px auto 0;
    align-self: center;
    top: 40%;
    position: absolute;
    left: 0;
    right: 0;

    ${respondTo.sm`
      width: 100%;
      margin: ${props => props.audience === 'hcp' ? '20px 10px 0' : '20px auto 0'};
    `} 
`;

export const TextBoxContent = styled(HeadingA)`
    color: ${green};
    margin: auto;
    text-align: center;
    width: 90%;
    display: flex;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 10px 0;
  img {
    height: 16px;
    margin-right: 10px;
  }
  a {
    margin: 10px 25px;
    padding: 10px 29px;
  }
`;