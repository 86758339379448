export const black = '#000000';
export const altBlack = '#021B31';
export const darkText = '#0c2340';
export const footerGray = '#37424b';
export const gray = '#707070';
export const lightGray = '#ebebeb';
export const offWhite = '#c5c5c5';
export const white = '#FFFFFF';
export const green = '#00857c';
export const tealTransparent = 'rgb(110, 206, 178, 0.44)';
export const teal = '#6ECEB2'
export const lightTeal = '#e6f3f2';
export const darkLinkBlue = '#0C2340';

export const footerGrey = footerGray;
export const grey = gray;
export const lightGrey = lightGray;
