import styled from 'styled-components';
import { respondTo } from '../../../styles/shared/breakpoints';
import { BodyA, BodyC } from '../../../styles/shared/typography';

export const ListContainer = styled.div`
  ${respondTo.sm`
    flex: 1;
    align-items: center;
    display: flex;
  `}
`;

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  ${respondTo.sm`
      flex-direction: column; 
  `}
`;

export const Icon = styled.img`
  width: 90px;
`;

export const CopyWrapper = styled.div`
  display: block;
  margin-left: 10px;
  width: 195px;
`;

export const BodyText = styled(BodyA)`
  margin-top: 5px;
  text-align: left;

  ${respondTo.sm`
  text-align: ${({ audience }) => audience==="hcc" ? 'center' : 'left'};
  `}
`;

export const SubCopy = styled(BodyC)`
  margin-top: 5px;
`;
