import React from 'react';
import { FooterContainer, ContentWrapper, LogoRow, MerckLogoWrapper, AccessibilityLogoWrapper, TextWrapper } from './styles';
import { footerCopy } from '../../../copy/global';
import FooterLinks from './FooterLinks';
import { FooterText } from '../../../styles/shared/typography';
import { Link } from 'gatsby';

const Footer = ({footerAudience}) => {
  const {footerTop,logos, legalLinks, copyrightGlobal} = footerCopy;

  return (
    <FooterContainer>
      <ContentWrapper>
        <TextWrapper>
          <FooterText>{footerTop}</FooterText>
        </TextWrapper>

          <LogoRow>
            <MerckLogoWrapper>
                <img src={logos.merck.src} alt={logos.merck.alt} />
            </MerckLogoWrapper>
            <FooterLinks data={legalLinks} />
          </LogoRow>
          
          <TextWrapper >
            <FooterText dangerouslySetInnerHTML={{ __html: copyrightGlobal  } } />
          </TextWrapper>
          
          <AccessibilityLogoWrapper>
              <Link to={logos.accessibility.href} target="_blank">
                <img src={logos.accessibility.src} alt="accessibility logo" />
              </Link>
          </AccessibilityLogoWrapper>
      </ContentWrapper>
    </FooterContainer >
  );
}

export default Footer;
