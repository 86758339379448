import styled, { css } from 'styled-components';
import { altBlack, black, darkText, footerGray, green, white } from './colors';
import { createResponsiveFontRules } from '../../util/functions';
import { Link } from 'gatsby';

const weightMap = {
  light: '300',
  regular: '400',
  bold: '700'
}

/**
 * TEXT UTILITIES
 */

const standardTextStyles = css`
  font-family: 'invention', sans-serif;
  display: block;
  font-weight: 400;
  color: ${({ theme }) => theme === 'light' ? white : black};
`;

export const getLinkStyles = css`
  a {
    text-decoration: underline;
    cursor: pointer;
    font-weight: 700;
    color: inherit;

    &:hover,
    &:active,
    &:focus {
      //
    }
  }
`;

export const getBoldStyles = css`
  b,
  strong {
    font-weight: 700;
  }
`;

const bodyStyles = css`
  ${getLinkStyles}
  ${getBoldStyles}

  i,
  em {
    font-style: italic;
  }
`;

export const noUnderline = css`
  &,  
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
`;

export const buttonStyleReset = css`
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
`;

/**
 * TEXT COMPONENTS
 */

// Welcome to
export const PopupTextA = styled.p`
  ${createResponsiveFontRules(28, 32, 50, 54)}
  ${standardTextStyles}
  font-weight: 300;
  letter-spacing: 0.04em;
`;

// Prompt and YES/NO buttons
export const PopupTextB = styled.p`
  ${createResponsiveFontRules(17, 21, 30, 34)}
  ${standardTextStyles}
  font-weight: 700;
`;

// The Merck Access Program may
export const HeadingA = styled.h2`
  ${createResponsiveFontRules(16, 20, 18, 21)}
  ${standardTextStyles}
  font-weight: 700;
  color: ${({ theme }) => theme === 'light' ? white : green};
`;

// To Learn More
export const HeadingB = styled.h2`
  ${createResponsiveFontRules(19, 22)}
  ${standardTextStyles}
  font-weight: 700;
  color: ${({ theme }) => theme === 'light' ? white : green};
`;

export const BodyA = styled.p`
  ${createResponsiveFontRules(15, 17)}
  ${standardTextStyles}
  ${bodyStyles}
`;

export const BodyB = styled.p`
  ${createResponsiveFontRules(12, 15)}
  ${standardTextStyles}
  ${bodyStyles}
  font-weight: ${({ weight }) => weight ? weightMap[weight] : '400'};
  color: ${({ theme }) => theme === 'light' ? white : darkText};
`;

export const BodyC = styled.p`
  ${createResponsiveFontRules(12, 15)}
  ${standardTextStyles}
  ${bodyStyles}
  font-weight: 300;
  color: ${altBlack}
`;

export const ButtonText = styled.span`
  ${createResponsiveFontRules(15, 17)}
  ${standardTextStyles}
  ${noUnderline}
  ${buttonStyleReset}
  font-weight: 700;
  color: ${white};
`;

export const LinkText = styled(Link)`
  ${createResponsiveFontRules(13, 15)}
  ${standardTextStyles}
  ${noUnderline}
  cursor: pointer;
  font-weight: ${({ weight }) => weight ? weightMap[weight] : '700'};
  color: ${({ theme }) => theme === 'light' ? white : darkText};
`;
  
export const FooterText = styled.p`
  ${createResponsiveFontRules(11, 15)}
  ${standardTextStyles}
  color: ${({ theme }) => theme === 'light' ? white : footerGray};
`;

// @TODO: Letter-spacing may need to be tweaked if we need to match comp (single line) at 320px?
export const FooterLegal = styled(Link)`
  ${createResponsiveFontRules(10, 12, 11, 13)}
  ${standardTextStyles}
  ${noUnderline}
  ${buttonStyleReset}
  display: inline-block;
  font-weight: 700;
  color: ${({ theme }) => theme === 'light' ? white : green};
`;
